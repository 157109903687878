import { megaBytesToBytes } from "@/shared/lib/utils/megabytes";
import { AxiosError } from "axios";
import { toast } from "react-toastify";

import { ArticleAPI } from "../api";
import i18n from "../i18n";
import { ETags } from "../shared/consts/common";

export const MAX_UPLOAD_IMAGE_SIZE = megaBytesToBytes(0.5);

const imageUploaderErrorHandler = (error: string) => {
  toast.error(error, {
    position: "bottom-left",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
  return null;
};

export const uploadImage = async (
  file: File,
  folder: UploadImageFolderType,
  maxSize = MAX_UPLOAD_IMAGE_SIZE
) => {
  if (file.size > maxSize) {
    return imageUploaderErrorHandler(i18n.t("ImageSizeExceeded"));
  }

  try {
    const response = await ArticleAPI.uploadImage(file, folder);
    return response.data;
  } catch (error) {
    const statusCode = (error as AxiosError).response?.status;

    if (statusCode === 413) {
      return imageUploaderErrorHandler(i18n.t("ImageSizeExceeded"));
    }

    if (statusCode === 400) {
      return imageUploaderErrorHandler(i18n.t("ImageExist"));
    }

    return imageUploaderErrorHandler(i18n.t("ImageUploadDefaultError"));
  }
};

export const editorUploadImage = (
  file: File,
  folder: UploadImageFolderType
) => {
  if (file.size > MAX_UPLOAD_IMAGE_SIZE) {
    imageUploaderErrorHandler(i18n.t("ImageSizeExceeded"));
    return {
      success: 0,
    };
  }

  return ArticleAPI.uploadImage(file, folder)
    .then((res) => ({
      success: 1,
      file: {
        url: res.data,
      },
    }))
    .catch((error) => {
      const statusCode = (error as AxiosError).response?.status;

      if (statusCode === 413) {
        return imageUploaderErrorHandler(i18n.t("ImageSizeExceeded"));
      }

      if (statusCode === 400) {
        return imageUploaderErrorHandler(i18n.t("ImageExist"));
      }

      return imageUploaderErrorHandler(i18n.t("ImageUploadDefaultError"));
    });
};

export const getAllArticles = async (): Promise<
  Nullable<ArticleResponse[]>
> => {
  try {
    const items = await ArticleAPI.getAllArticles();
    return items;
  } catch (error) {
    console.info(error);
    return null;
  }
};

export const getPartialArticles = async (
  start: number,
  count: number
): Promise<Nullable<ArticleResponse[]>> => {
  try {
    return await ArticleAPI.getPartialArticles(start, count);
  } catch (error) {
    console.info(error);
    return null;
  }
};

export const getArticleByIndex = async (
  index: ArticleResponse["article_index"]
): Promise<Nullable<ArticleDetail>> => {
  try {
    const [items, item] = await Promise.all([
      getAllArticles(),
      ArticleAPI.getArticleDetailByIndex(index),
    ]);
    const foundItem = items?.find((item) => item.article_index === index);
    if (item && foundItem) {
      return {
        ...foundItem,
        ...item,
        content: JSON.parse(item.content),
      };
    } else {
      throw new Error(`${index} ${i18n.t("NotFound")}`);
    }
  } catch (error) {
    console.info(error);
    return null;
  }
};

export const getLatestNews = async (): Promise<Nullable<ArticleResponse[]>> => {
  try {
    const items = await ArticleAPI.getArticlesByTagIndex(ETags.novosti);
    return items;
  } catch (error) {
    console.info(error);
    return null;
  }
};

export const getMainArticles = async (): Promise<
  Nullable<ArticleResponse[]>
> => {
  try {
    const items = await ArticleAPI.getMainArticles();
    return items;
  } catch (error) {
    console.info(error);
    return null;
  }
};

export const getImportantArticles = async (): Promise<
  Nullable<ArticleResponse[]>
> => {
  try {
    const items = await ArticleAPI.getImportantArticles();
    return items;
  } catch (error) {
    console.info(error);
    return null;
  }
};

export const getSpecialArticle = async (): Promise<
  Nullable<ArticleResponse>
> => {
  try {
    const items = await getAllArticles();
    const itemIndex = items?.find((item) => item.is_special)?.article_index;
    return itemIndex ? getArticleByIndex(itemIndex) : null;
  } catch (error) {
    console.info(error);
    return null;
  }
};

export const getArticlesByTagIndex = async (
  index?: string
  // start?: number,
  // end?: number
): Promise<Nullable<ArticleResponse[]>> => {
  if (!index) return null;
  try {
    const items = await ArticleAPI.getArticlesByTagIndex(index);
    return items;
  } catch (error) {
    console.info(error);
    return null;
  }
};

export const likeArticle = async (article_id: number) => {
  try {
    await ArticleAPI.likeArticle(article_id);
  } catch (error) {
    console.info(error);
    return null;
  }
};

export const addVoteBlock = async (data: VoteResponse) => {
  try {
    const respose = await ArticleAPI.addVoteBlock(data);
    return respose;
  } catch (error) {
    console.info(error);
    return null;
  }
};

export const editVoteBlock = async (data: VoteResponse) => {
  try {
    const respose = await ArticleAPI.editVoteBlock(data);
    return respose;
  } catch (error) {
    console.info(error);
    return null;
  }
};

export const increaseViewCountArticle = async (article_id: number) => {
  try {
    await ArticleAPI.increaseViewCountArticle(article_id);
  } catch (error) {
    console.info(error);
    return null;
  }
};

export const addArticle = async (data: Partial<ArticleDetail>) => {
  try {
    const response = await ArticleAPI.addArticle(data);
    return response;
  } catch (error) {
    console.info(error);
    return null;
  }
};

export const editArticle = async (data: ArticleDetail) => {
  try {
    const response = await ArticleAPI.editArticle(data);
    return response;
  } catch (error) {
    console.info(error);
    return null;
  }
};

export const deleteArticle = async (article_id: number) => {
  try {
    const response = await ArticleAPI.deleteArticle(article_id);
    return response;
  } catch (error) {
    console.info(error);
    return null;
  }
};

export const checkArticleIndexExist = async (article_index: string) => {
  const article = await getArticleByIndex(article_index);
  return !!article;
};
