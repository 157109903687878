'use client';

import { experimental_extendTheme as extendTheme } from '@mui/material/styles';

import { colors } from '../../consts';
import { OpTheme } from '../../providers';
import { pxToVwWithBreakpoints } from '../../utils';

export const AlertTheme = extendTheme({
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          background: colors.white.t_100,
          color: colors.black.t_100,
          ...pxToVwWithBreakpoints({
            padding: OpTheme.spacing(3),
          }),
        },
      },
    },
  },
});
