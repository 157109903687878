export const fontWeights = {
  light: 300,
  normal: 450,
  medium: 500,
  semiBold: 550,
  bold: 700,
};

export const fontWeightBase = fontWeights.normal;

export const fontFamilies = {
  base: 'var(--font-suisse-intl), Arial, sans-serif',
};
