import { megaBytesToBytes } from "@/shared/lib/utils/megabytes";
import classNames from "classnames";
import { MouseEventHandler, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { uploadImage } from "../controllers/article-controller";

interface ImageUploaderProps {
  value?: string;
  folder: UploadImageFolderType;
  onChange: (value?: string) => void;
  isInvalid?: boolean;
}

export const MAX_UPLOAD_IMAGE_SIZE = megaBytesToBytes(0.5);

export default function ImageUploader({
  value,
  folder,
  onChange,
  isInvalid,
}: ImageUploaderProps) {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      setLoading(true);
      const response = await uploadImage(file, folder, MAX_UPLOAD_IMAGE_SIZE);
      response && onChange(response);

      setLoading(false);
    }
  };

  const handleDelete: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    onChange("");
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  return (
    <label
      className={classNames([
        "image-uploader",
        value && "is-uploaded",
        loading && "is-loading",
        isInvalid && "is-invalid",
      ])}
    >
      <span className="image-uploader__loading" />
      <input
        ref={inputRef}
        className="image-uploader__input"
        type="file"
        onChange={handleChange}
        accept="image/jpg, image/jpeg, image/png, image/webp"
        disabled={loading}
      />
      <div className="image-uploader__btns">
        <button className="image-uploader__btn-delete" onClick={handleDelete} />
        <p className="btn btn-shadow size-s image-uploader__btn-download">
          <span className="icon icon-download-pic btn__icon" />
          <span className="btn__text">{t("Cover")}</span>
        </p>
      </div>
      {value && <img src={value} alt={t("UploadImage")} />}
    </label>
  );
}
